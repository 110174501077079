window.addEventListener('load', function () {
    var insuranceCase = new Vue({
        el: '#formInsuranceCase',
        data: {
            sent: false,
            errors: '',
            formName: {
                name: 'formInsuranceCase',
                description: 'Страховой случай'
            },
            form: {
                delegateName: {
                    "f-label": "Укажите Ваше имя *",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                phone: {
                    "f-label": "Введите номер телефона для обратной связи *",
                    "f-type": "phone",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^\\+7\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$"
                },
                email: {
                    "f-label": "Введите эл.почту для обратной связи *",
                    "f-type": "email",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {}
                },
                insuranceCase: {
                    "f-label": "Страховой случай *",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "insuranceCaseDic",
                    "f-plain-value" : "",
                    "f-required": true,
                    "f-style": {}
                },
                caseTerritory: {
                    "f-label": "Укажите территорию страхового случая *",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                caseDescription: {
                    "f-label": "Опишите кратко Вашу проблему *",
                    "f-type": "text-area",
                    "f-text-area-rows": 4,
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {width: '100%'}
                }               
            },
            dictionaries: {
                insuranceCaseDic: [
                    {name: "health", description: "Здоровье"},
                    {name: "transport", description: "Транспорт (ОСАГО, КАСКО)"},
                    {name: "travel", description: "Путешествия"}
                ]
            }
        },
        methods: {
            refreshForm() {
                for (var key in this.form) {
                    this.form[key]['f-value'] = '';
                }
            },
            onSubmit(evt) {
                evt.preventDefault();

                this.errors = "";
                let errorsCnt = validateForm(this.form);
                if (errorsCnt > 0) {
                    this.errors = "Необходимо корректно заполнить все поля, отмеченные звездочкой (*)";
                }

                if (errorsCnt == 0) {
                
                    let mailMessage = constructMailMessage(this.form, this.formName.description);

                    const emailOptions = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        data: {msg: mailMessage, formType: this.formName.name, msgType: this.form.insuranceCase["f-value"]},
                        url: '/local/form/submit-msg-form.php',
                    };

                    this.$axios(emailOptions)
                        .then(res => { 
                            // console.log('msg was sent ' + mailMessage);
                            this.sent = true; 
                            this.refreshForm();
                            $.fancybox.close({src: '#' + this.form.name});
                            $.fancybox.open('<div  id="thanks" class="popup ths"> <div class="ths-box"> <div class="ths-marg"> <p class="ths-t1">заявка принята</p> <p class="ths-t2">Мы свяжемся с вами <br>в ближайшее время</p> </div> </div> </div>');
                        })
                        .catch(function (error) {
                            console.log(error);
                            $.fancybox.open('<div class="message"><h2>Ошибка</h2></div>');
                        });
                }
            }            
        },
        computed: {
            watchInsuranceCase() {
                return this.form.insuranceCase['f-value'];
            }
        },
        watch: {
            watchInsuranceCase: function() {
                var vm = this;
                try {
                    this.form.insuranceCase['f-plain-value'] = this.dictionaries[this.form.insuranceCase['f-dic']] ? 
                        this.dictionaries[this.form.insuranceCase['f-dic']].find(function(dic){
                            return dic.name === vm.form.insuranceCase['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}    
            }
        }
    });
})